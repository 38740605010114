/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar } from '@mybridge/ui/avatar';
import { CloseButton } from '@mybridge/ui/close-button';
import { useOutsideClick } from '@mybridge/ui/hooks';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CommentForm } from 'v4/components/comment/comment-form';
import {
  PostCommentFormContext,
  usePostCommentFormContext,
} from 'v4/components/comment/comment-form/context';
import { PostCommentRowContext } from 'v4/components/comment/comment-row/context';
import { getUserFullName } from 'v4/lib/commons';

export const PostCommentEditForm = ({ ...props }) => {
  const { mode, setMode, post, comment, level, refetch } = useContext(
    PostCommentRowContext
  );
  const postCommentFormContext = usePostCommentFormContext({
    post,
    parent: comment?.parent,
    refetch,
    showComments: true,
    level,
    comment: comment,
    onCreate: () => {
      setMode('view');
    },
    onSave: () => {
      setMode('view');
    },
  });

  const { expanded, setExpanded, hasContent } = postCommentFormContext ?? {};

  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const userFullName = getUserFullName(userProfileInfo);
  const cfRef = useRef();
  useOutsideClick({
    ref: cfRef,
    handler: () => handleFieldBlur(),
  });
  const handleFieldBlur = () => {
    if (!hasContent) {
      setExpanded(false);
    }
  };
  return (
    <PostCommentFormContext.Provider
      value={{
        ...(postCommentFormContext ?? {}),
      }}
    >
      <Box py={2} borderTop="1px solid" borderColor="brandGray.500" {...props}>
        <HStack alignItems="flex-start" ref={cfRef}>
          <Stack>
            <Avatar name={userFullName} />
          </Stack>
          <CommentForm />
          {mode === 'edit' ? (
            <CloseButton onClick={(e) => setMode('view')} />
          ) : (
            ''
          )}
        </HStack>
      </Box>
    </PostCommentFormContext.Provider>
  );
};
